export const getApps = () => {
  return [
    {
      name: 'eView',
      description: 'Dépenses & Consommation',
      url: 'https://eview.eleneo.fr/',
      active: true,
      roles: ['read:eview', 'admin:eview', 'sites:eview', 'superuser'],
    },
    {
      name: 'eView Premium',
      description: 'Dépenses & Consommation',
      url: 'https://eview-premium.eleneo.fr/',
      active: true,
      roles: ['read:eview-premium', 'admin:eview-premium', 'sites:eview-premium', 'superuser'],
    },
    {
      name: 'eFlex',
      description: 'Stratégie de couverture',
      url: 'https://eflex.eleneo.fr/',
      active: true,
      roles: ['read:eflex', 'admin:eflex', 'superuser'],
    },
    {
      name: 'eInvoice',
      description: 'Suivi de la facturation',
      url: 'https://einvoice.eleneo.fr/',
      active: true,
      roles: ['read:einvoice', 'admin:einvoice', 'superuser'],
    },
    {
      name: 'eSite',
      description: 'Performance des sites',
      url: 'https://esite.eleneo.fr/',
      active: true,
      roles: ['read:esite', 'admin:esite', 'superuser'],
    },
    {
      name: 'eContract',
      description: 'Contract Management',
      url: 'https://econtract.eleneo.fr',
      active: true,
      roles: ['superuser', 'read:econtrat', 'admin:econtrat'],
    },
    {
      name: 'eBudget',
      description: 'Budget Management',
      url: 'https://ebudget.eleneo.fr',
      active: true,
      roles: ['superuser', 'read:ebudget', 'admin:ebudget'],
    },
    {
      name: 'eData',
      description: 'Management des données de facturation',
      url: 'https://edata.eleneo.fr',
      active: true,
      roles: ['superuser'],
    },
    {
      name: 'emcm',
      description: 'Achat & Suivi',
      url: 'https://emcm.eleneo.local/',
      active: true,
      roles: ['read:emcm', 'superuser'],
    },
    {
      name: 'emcm Carrefour',
      description: 'Achat & Suivi Carrefour',
      url: 'https://emcm-carrefour.eleneo.local/',
      active: true,
      roles: ['read:emcm:car', 'superuser'],
    },
    {
      name: 'Valo On Demand',
      description: 'Admin - Valorisation à la demande',
      url: 'https://valo.eleneo.fr/',
      active: true,
      roles: ['superuser', 'valodemand:admin'],
    },
    {
      name: 'eAdmin',
      description: 'Administration des utilisateurs',
      url: 'https://admin.eleneo.fr/',
      active: true,
      roles: ['superuser', 'read:eadmin', 'admin:eadmin'],
    },
  ]
}
