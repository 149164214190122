import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getApps } from 'actions/apps'
import { Item } from './Item'

const Home = () => {
  const user = useSelector((state) => state.oidc.user)
  const allApps = useSelector(getApps)

  const apps = useMemo(() => {
    if (!allApps) return []
    return allApps.filter((app) => app.roles.some((role) => user.profile.role.includes(role)))
  }, [allApps, user.profile.role])

  return (
    <div className="App">
      <div className="wrapper">
        <div className="homepage-slider">
          <div
            css={{
              margin: '2rem 0',
              display: 'grid',
              gap: '3rem',
              gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
              '@media (max-width: 1200px)': {
                gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
              },
              '@media (max-width: 1024px)': {
                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
              },
              '@media (max-width: 640px)': {
                gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
              },
            }}
          >
            {apps.map((app) => (
              <Item key={app.name} active={app.active} name={app.name} url={app.url} />
            ))}
          </div>

          <div style={{ textAlign: 'center', marginTop: '4rem' }}>
            <Link to={'/confidentialite/'}>{'Politique de confidentialité'}</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
