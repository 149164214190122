import React from 'react'
import { Navbar, Nav, NavItem } from 'reactstrap'
import { withRouter, NavLink } from 'react-router-dom'
import { translate } from 'react-i18next'

import { LogoEleneo } from 'components/LogoEleneo'

const TopNavAnonymous = ({ t }) => (
  <Navbar color="primary" expand className="topNav">
    <Nav navbar>
      <NavItem key="topNav.home">
        <NavLink to="/" className="logo" style={{ padding: '12px 55px 12px' }}>
          <LogoEleneo color="#f8f9fa" fill="#adb5bd" style={{ fontSize: '2.8rem' }} />
        </NavLink>
      </NavItem>
    </Nav>
  </Navbar>
)

export default translate()(withRouter(TopNavAnonymous))
