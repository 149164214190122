import React from 'react'
import { Table } from 'reactstrap'

export default () => (
  <div className="App">
    <div className="wrapper">
      <div className="homepage-slider">
        <br />
        <h1>POLITIQUE DE CONFIDENTIALITE</h1>
        <br />
        ELENEO SAS (dont le siège social est situé 93 rue de la Villette, 69003 LYON), en sa qualité
        de responsable du traitement, attache une grande importance à la protection et au respect de
        votre vie privée. La présente politique vise à vous informer, conformément au Règlement
        n°2016-679 du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du
        traitement des données à caractère personnel et à la libre circulation de ces données
        (ci-après dénommé le « Règlement »), de nos pratiques concernant la collecte, l’utilisation
        et le partage des informations que vous êtes amenés à nous fournir par le biais de nos
        applications SAS.
        <br />
        Cette politique a pour but de vous informer sur les catégories de données personnelles que
        nous pourrions recueillir ou détenir sur vous, comment nous les utilisons, avec qui nous les
        partageons, comment nous les protégeons, et les droits dont vous disposez sur vos données
        personnelles.
        <br />
        <br />
        <h2>1. Les données que nous collectons</h2>
        <br />
        En utilisant les applications SAS fournies par ELENEO SAS, vous êtes amenés à nous
        transmettre des informations, dont certaines sont de nature à vous identifier et constituent
        de ce fait des données à caractère personnel (ci-après dénommées les « Données »). C’est
        notamment le cas lorsque vous vous authentifiez sur les applications. Ces informations
        contiennent notamment les données :
        <ul>
          <li>
            Données du compte : désignent les données que vous fournissez lors de la création d’un
            compte par l’administrateur de l’application.
          </li>
          <li>
            Données relatives à la navigation : désignent les données que nous collectons lors de
            votre navigation sur les applications telles que notamment la date, l’heure de la
            connexion et/ou navigation, le type de navigateur, la langue du navigateur, son adresse
            IP.
          </li>
        </ul>
        <br />
        <h2>2. Comment utilisons-nous les données que nous collectons ?</h2>
        <br />
        Nous utilisons les Données que nous recueillons afin de :<br />
        <br />
        <Table style={{ width: '600px' }}>
          <thead>
            <tr>
              <th>Finalités</th>
              <th>Base légale</th>
            </tr>
          </thead>
          <tr>
            <td>Créer un compte et vous authentifier</td>
            <td>Exécution d’un contrat ou exécution de mesures précontractuelles ;</td>
          </tr>
          <tr>
            <td>Communiquer des informations relatives au service fourni</td>
            <td>Exécution d’un contrat ou exécution de mesures précontractuelles ;</td>
          </tr>
        </Table>
        <br />
        Lors de la collecte des Données, vous serez informé si certaines Données doivent être
        obligatoirement renseignées ou si elles sont facultatives. Les Données identifiées par un
        astérisque au sein du formulaire de collecte sont obligatoires. A défaut, l’exécution de
        votre demande pourra être restreinte.
        <br />
        <br />
        <h2>
          3. Qui sont les destinataires des données que nous collectons et pour quelles raisons leur
          transmettons-nous ces données ?
        </h2>
        <br />
        <br />
        <h3>3.1 Données transférées aux autorités et/ou organismes publics</h3>
        <br />
        Conformément à la règlementation en vigueur, les Données peuvent être transmises aux
        autorités compétentes sur requête et notamment aux organismes publics, aux auxiliaires de
        justice, aux officiers ministériels, aux organismes chargés d’effectuer le recouvrement de
        créances, exclusivement pour répondre aux obligations légales, ainsi que dans le cas de la
        recherche des auteurs d’infractions commises sur internet.
        <br />
        <br />
        <h3>3.2 Données transférées à des tiers</h3>
        <br />
        Nous travaillons en étroite collaboration avec des entreprises tierces qui peuvent avoir
        accès à vos Données, et notamment avec :<br />
        <br />
        <ul>
          <li>
            NewsLetter2Go auxquels nous recourons aux fins de campagnes d’e-mailing d’information ;
          </li>
        </ul>
        Nous transmettons uniquement à ces tiers les Données dont ils ont besoin pour effectuer
        leurs services, et nous exigeons qu’ils n’utilisent pas vos Données à d’autres fins. Ces
        tiers n’agiront que conformément à nos instructions et seront contractuellement tenus
        d’assurer un niveau de sécurité et de confidentialité de vos Données identique au notre et
        de se conformer à la règlementation applicable sur la protection des données à caractère
        personnel.
        <br />
        <br />
        <h2>4. Combien de temps conservons-nous vos données ?</h2>
        <br />
        Vos Données ne seront pas conservées au-delà de la durée strictement nécessaire aux
        finalités poursuivies telles qu’énoncées dans la Politique et ce conformément au Règlement
        et aux lois applicables.
        <br />
        A cet égard, les Données utilisées aux fins :<br />
        <br />
        <ul>
          <li>
            D’authentification sont conservées pendant toute la durée de la relation contractuelle,
            puis archivées pendant 1 mois conformément aux délais de prescription
          </li>
          <li>De suivi technique des connexions sont conservées pendant une durée de 2 ans</li>
        </ul>
        Vos Données sont effacées lorsque les durées de conservation expirent. Néanmoins, vos
        Données pourront être archivées au-delà des durées prévues pour les besoins de la recherche,
        la constatation et de la poursuite des infractions pénales dans le seul but de permettre, en
        tant que de besoin, la mise à disposition de vos Données à l’autorité judiciaire.
        <br />
        <br />
        L’archivage implique que vos Données ne seront plus consultables en ligne mais seront
        extraites et conservées sur un support autonome et sécurisé.
        <br />
        <br />
        <h2>5. Vos données sont-elles transférées, comment et où ?</h2>
        <br />
        Vos Données ne font pas l’objet de transfert hors du territoire de l’Union européenne.
        <br />
        <br />
        <h2>6. Comment vos données sont protégées ?</h2>
        <br />
        Nous prenons des mesures adéquates sur le plan de la technique et de l’organisation pour
        interdire l’accès non autorisé ou la modification, divulgation, perte ou destruction de vos
        Données. Il importe que vous préserviez la confidentialité de vos identifiants de façon à
        empêcher une utilisation illicite de votre compte.
        <br />
        <br />
        <h2>7. Quels sont vos droits sur vos données ?</h2>
        <br />
        Conformément aux lois et règlements applicables en matière de protection des données
        personnelles, vous bénéficiez d’un certain nombre de droits relatifs à vos Données, à savoir
        :<br />
        <br />
        <ul>
          <li>
            <strong>Un droit d’accès et d’information</strong> : vous avez le droit d’être informé
            de manière concise, transparente, intelligible et facilement accessible de la manière
            dont vos Données sont traitées. Vous avez également le droit d’obtenir (i) la
            confirmation que des Données vous concernant sont traitées et, le cas échéant (ii)
            d’accéder à ces Données et d’en obtenir une copie.
          </li>
          <li>
            <strong>Un droit de rectification</strong> : vous avez le droit d’obtenir la
            rectification des Données inexactes vous concernant. Vous avez également le droit de
            compléter les Données incomplètes vous concernant, en fournissant une déclaration
            complémentaire. En cas d’exercice de ce droit, nous nous engageons à communiquer toute
            rectification à l’ensemble des destinataires de vos Données.
          </li>
          <li>
            <strong>Un droit d’effacement</strong> : dans certains cas, vous avez le droit d’obtenir
            l’effacement de vos Données. Cependant, ceci n’est pas un droit absolu et nous pouvons
            pour des raisons légales ou légitimes conserver ces Données.
          </li>
          <li>
            <strong>Un droit à la limitation du traitement</strong> : dans certains cas, vous avez
            le droit d’obtenir la limitation du traitement sur vos Données.
          </li>
          <li>
            <strong>Un droit à la portabilité des Données</strong> : vous avez le droit de recevoir
            vos Données que vous nous avez fournies, dans un format structuré, couramment utilisé et
            lisible par une machine, pour votre usage personnel ou pour les transmettre à un tiers
            de votre choix. Ce droit ne s’applique que lorsque le traitement de vos Données est basé
            sur votre consentement, sur un contrat ou que ce traitement est effectué par des moyens
            automatisés.
          </li>
          <li>
            <strong>Un droit d’opposition au traitement</strong> : vous avez le droit de vous
            opposer à tout moment au traitement de vos Données pour les traitements basés sur notre
            intérêt légitime, une mission d’intérêt public et ceux à des fins de prospection
            commerciale. Ceci n’est pas un droit absolu et nous pouvons pour des raisons légales ou
            légitimes refuser votre demande d’opposition.
          </li>
          <li>
            <strong>Le droit de retirer votre consentement à tout moment</strong> : vous pouvez
            retirer votre consentement au traitement de vos Données lorsque le traitement est basé
            sur votre consentement. Le retrait du consentement ne compromet pas la licéité du
            traitement fondé sur le consentement effectué avant ce retrait.
          </li>
          <li>
            <strong>Le droit de déposer une plainte auprès d’une autorité de contrôle</strong> :
            vous avez le droit de contacter votre autorité de protection des données pour vous
            plaindre de nos pratiques de protection des données personnelles.
          </li>
          <li>
            <strong>
              Le droit de donner des directives concernant le sort de vos données après votre décès
            </strong>{' '}
            : vous avez le droit de nous donner des directives concernant l’utilisation de vos
            Données après votre décès.
          </li>
        </ul>
        Pour exercer ces droits, vous pouvez nous contacter à l’adresse suivante :
        support@eleneo.fr.
        <br />
        <br />
        Notez que nous pouvons exiger un justificatif de votre identité pour l’exercice de ces
        droits.
        <br />
        <br />
        <h2>8. Modification de notre politique de confidentialité</h2>
        <br />
        Nous pouvons être amenés à modifier occasionnellement la présente politique, afin notamment
        de se conformer à toutes évolutions réglementaires, jurisprudentielles, éditoriales ou
        techniques. Le cas échéant, nous changerons la date de « dernière mise à jour » et
        indiquerons la date à laquelle les modifications ont été apportées. Lorsque cela est
        nécessaire, nous vous informerons et/ou solliciterons votre accord. Nous vous conseillons de
        consulter régulièrement cette page pour prendre connaissance des éventuelles modifications
        ou mises à jour apportées à notre politique.
        <br />
        <br />
        <h2>9. Contact</h2>
        <br />
        Pour toute question relative à la présente politique ou pour toute demande relative à vos
        Données, vous pouvez nous contacter en :<br />
        <br />
        <ul>
          <li>
            Adressant un courriel à notre Délégué à la Protection des Données à l’adresse suivante :
            support@eleneo.fr ; ou
          </li>
          <li>Adressant un courrier à l’adresse suivante :</li>
        </ul>
        A l’attention du Délégué à la Protection des Données
        <br />
        Eleneo SAS
        <br />
        18 rue Saint-Fiacre
        <br />
        75002 Paris
        <br />
        <br />
        Version mise à jour le 03/01/2019.
        <br />
        <br />
      </div>
    </div>
  </div>
)
