import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

import 'assets/css/index.sass'

import * as env from './helpers/env'
import { init } from 'commons'

import App from './App'
import registerServiceWorker from './registerServiceWorker'
import configureStore from './configureStore'

init(env)

ReactDOM.render(<App store={configureStore()} />, document.getElementById('root'))

registerServiceWorker()
