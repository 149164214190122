import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import TopNavAnonymous from '../topnav/TopNavAnonymous'
import Views from '../route/Views'

const TemplateAnonymous = () => (
  <Container fluid>
    <Row>
      <Col className="main-col">
        <Row>
          <TopNavAnonymous />
        </Row>
        <Views />
      </Col>
    </Row>
  </Container>
)

export default TemplateAnonymous
