import { combineReducers } from 'redux'
import { reducer as oidc } from 'redux-oidc'
import { reducer as notifications } from 'react-notification-system-redux'
import get from 'lodash/get'

import { apiStatusReducer, userReducer } from 'commons'

const app = combineReducers({
  oidc,
  apiStatus: apiStatusReducer,
  user: userReducer,
  notifications,
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT_SUCCESS') {
    return app(undefined, action)
  }

  return app(state, action)
}

export default rootReducer

export const getUor = (state) => get(state, ['oidc', 'user', 'profile', 'uor'], false)
