import React from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'

import Home from '../home/Home'
import PrivacyPolicy from './PrivacyPolicy'

const Views = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/home/" component={Home} />
    <Route path="/confidentialite/" component={PrivacyPolicy} />
  </Switch>
)

export default withRouter(Views)
