import React from 'react'
import { Provider } from 'react-redux'
import { OidcProvider } from 'redux-oidc'
import { I18nextProvider } from 'react-i18next'
import { Route, BrowserRouter as Router } from 'react-router-dom'

import { userManager, i18n } from 'commons'

import Root from './components/route/Root'

import fr from './locales/fr.json'
import en from './locales/en.json'

i18n.addResourceBundle('fr', 'translation', fr)
i18n.addResourceBundle('en', 'translation', en)

const App = ({ store }) => (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <Router>
          <Route path="/" component={Root} />
        </Router>
      </OidcProvider>
    </Provider>
  </I18nextProvider>
)

export default App
