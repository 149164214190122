import React from 'react'

export const Item = ({ active, url, name }) => {
  return (
    <div
      tabIndex={0}
      css={{
        width: '100%',
        height: 200,
        padding: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0.15rem',
        background: active ? 'unset' : '#e0e0e0',
        '&:focus': {
          outline: 'none',
        },
      }}
      className={active ? 'item item-active' : 'item'}
    >
      <div
        css={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          css={{
            fontFamily: 'Merlo, sans-serif',
            fontSize: 35,
            textAlign: 'center',
            lineHeight: 1.2,
          }}
        >
          {name}
        </div>
      </div>

      <div
        css={{
          marginTop: '1rem',
          flex: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <button
          className="btn btn-sm btn-primary"
          disabled={!active}
          onClick={() => window.open(url, '_blank')}
        >
          Ouvrir
        </button>
      </div>
    </div>
  )
}
