import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { compose, withState } from 'recompose'
import PropTypes from 'prop-types'

// import { UserPanel } from 'commons';
import UserPanel from './UserPanel'

import Notifs from '../notifications/Notifs'
import TopNav from '../topnav/TopNav'
import Views from '../route/Views'

const Template = ({ userPanelOpen, setUserPanelOpen }) => (
  <Container fluid>
    <Notifs />
    <Row>
      <UserPanel userPanelOpen={userPanelOpen} setUserPanelOpen={setUserPanelOpen} />
      <Col className="main-col">
        <Row>
          <TopNav userPanelOpen={userPanelOpen} setUserPanelOpen={setUserPanelOpen} />
        </Row>
        <Views />
      </Col>
    </Row>
  </Container>
)

Template.propTypes = {
  userPanelOpen: PropTypes.bool.isRequired,
  setUserPanelOpen: PropTypes.func.isRequired,
}

export default compose(withState('userPanelOpen', 'setUserPanelOpen', false))(Template)
