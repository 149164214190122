import * as appsProdAzure from '../api/appsProdAzure'
import * as appsLocal from '../api/appsLocal'
import { environment } from '../helpers/env'

export const getApps = () => {
  switch (environment) {
    case 'AZURE':
      return appsProdAzure.getApps()
    case 'LOCAL':
    default:
      return appsLocal.getApps()
  }
}
