export const getApps = () => {
  return [
    {
      name: 'eView',
      description: 'Dépenses & Consommation',
      url: 'http://eview.eleneo.local/',
      active: true,
      roles: ['read:eview', 'admin:eview', 'sites:eview', 'superuser'],
    },
    {
      name: 'eView Premium',
      description: 'Dépenses & Consommation',
      url: 'http://eview-premium.eleneo.local/',
      active: true,
      roles: ['read:eview-premium', 'admin:eview-premium', 'sites:eview-premium', 'superuser'],
    },
    {
      name: 'eFlex',
      description: 'Stratégie de couverture',
      url: 'http://eflex.eleneo.local/',
      active: true,
      roles: ['read:eflex', 'admin:eflex', 'superuser'],
    },
    {
      name: 'eInvoice',
      description: 'Suivi de la facturation',
      url: 'http://einvoice.eleneo.local/',
      active: true,
      roles: ['read:einvoice', 'admin:einvoice', 'superuser'],
    },
    {
      name: 'eSite',
      description: 'Performance des sites',
      url: 'http://esite.eleneo.local/',
      active: true,
      roles: ['read:esite', 'admin:esite', 'superuser'],
    },
    {
      name: 'eContract',
      description: 'Contract Management',
      url: 'http://econtract.eleneo.local',
      active: true,
      roles: ['superuser', 'read:econtract', 'admin:econtract'],
    },
    {
      name: 'eBudget',
      description: 'Budget Management',
      url: 'http://ebudget.eleneo.local',
      active: true,
      roles: ['superuser', 'read:ebudget', 'admin:ebudget'],
    },
    {
      name: 'eData',
      description: 'Management des données de facturation',
      url: 'http://edata.eleneo.local',
      active: true,
      roles: ['superuser'],
    },
    {
      name: 'emcm',
      description: 'Achat & Suivi',
      url: 'http://emcm.eleneo.local/',
      active: true,
      roles: ['read:emcm', 'superuser'],
    },
    {
      name: 'emcm Carrefour',
      description: 'Achat & Suivi Carrefour',
      url: 'http://emcm-carrefour.eleneo.local/',
      active: true,
      roles: ['read:emcm:car', 'superuser'],
    },
    {
      name: 'Valo On Demand',
      description: 'Admin - Valorisation à la demande',
      url: 'http://valo.eleneo.local/',
      active: true,
      roles: ['superuser', 'valodemand:admin'],
    },
    {
      name: 'eAdmin',
      description: 'Administration des utilisateurs',
      url: 'http://admin.eleneo.local/',
      active: true,
      roles: ['superuser', 'read:eadmin', 'admin:eadmin'],
    },
  ]
}
