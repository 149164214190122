import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'

class Notifs extends Component {
  render() {
    const { notifications } = this.props
    return <Notifications notifications={notifications} />
  }
}

Notifs.contextTypes = {
  store: PropTypes.object,
}

Notifs.propTypes = {
  notifications: PropTypes.array,
}

export default connect((state) => ({ notifications: state.notifications }))(Notifs)
