import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { reactI18nextModule } from 'react-i18next'

import en from './locales/en.json'
import fr from './locales/fr.json'

export const lngIso = (lanId) => {
  switch (lanId) {
    case 2:
      return 'en'
    case 1:
    default:
      return 'fr'
  }
}

i18n
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    fallbackLng: 'fr',
    debug: false,
    ns: 'translation',
    defaultNS: 'translation',

    interpolation: {
      escapeValue: false,
    },

    resources: {
      en,
      fr,
    },

    react: {
      wait: true,
      nsMode: 'default', // set it to fallback to let passed namespaces to translated hoc act as fallbacks
    },
  })

export default i18n
