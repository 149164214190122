import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Navbar, Nav, NavItem, Button } from 'reactstrap'
import { withRouter, NavLink } from 'react-router-dom'
import { translate } from 'react-i18next'
import compose from 'lodash/fp/compose'

import { mainLogo } from '../../helpers/logo'
import { LogoEleneo } from 'components/LogoEleneo'

const TopNav = ({ setUserPanelOpen, userPanelOpen, t, logo }) => (
  <Navbar color="primary" expand className="topNav">
    <Nav navbar>
      <NavItem key="topNav.home">
        {logo === null ? (
          <NavLink to="/" className="logo" style={{ padding: '12px 55px 12px' }}>
            <LogoEleneo color="#f8f9fa" fill="#adb5bd" style={{ fontSize: '2.8rem' }} />
          </NavLink>
        ) : (
          <NavLink to="/" className="logo">
            <img src={logo} alt={t('sidebar.logoAlt')} className="img-logo" />
          </NavLink>
        )}
      </NavItem>
    </Nav>
    <Nav className="ml-auto" navbar>
      <NavItem>
        <Button color="link" title="" onClick={() => setUserPanelOpen(!userPanelOpen)}>
          <i className="icon-user" />
          <i className="icon-smallarrow-down" />
        </Button>
      </NavItem>
    </Nav>
  </Navbar>
)

TopNav.propTypes = {
  setUserPanelOpen: PropTypes.func.isRequired,
  userPanelOpen: PropTypes.bool.isRequired,
}

export default compose(
  connect((state) => ({ logo: mainLogo(state) })),
  translate(),
  withRouter,
)(TopNav)
