import React, { Component } from 'react'
import { translate } from 'react-i18next'

class AccessDenied extends Component {
  render() {
    const { t } = this.props

    return (
      <div>
        <p>{t('access.denied')}</p>
        <p>
          <a href="https://portal.eleneo.fr/">{t('access.backToPortal')}</a>
        </p>
      </div>
    )
  }
}

export default translate()(AccessDenied)
